import React, {useEffect, useState} from 'react'
import axios from 'axios'

export default function ContactPage() {
  const [email, set_email] = useState("")

  useEffect(() => {
    axios({
      method: "get",
      url: "http://localhost:3001/site-info"    // a then akkor fut le ha jön vissza valami a requestben mint egy try catch blokk
    }).then(res => set_email(res.data.email))
  }, [])

  return (
    <div className='contact-container'>
      <h1>Let's work together</h1>
      <h2>{email}</h2>

      <form action="">
        <h2>name</h2>
        <input type="text"/>
        <h2>email</h2>
        <input type="text"/>
        <h2>phone</h2>
        <input type="text"/>
        <h2>message</h2>
        <textarea name="" id="" cols="" rows=""></textarea>
      </form>

      <button>send</button>
    </div>
  )
}
