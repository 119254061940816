import React from 'react'
import SocialLinks from './micro/SocialLinks'

export default function Footer() {
  return (
    <footer>

      <div>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fuga, necessitatibus.</div>
      <div className='separator'/>

      <div>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fuga, necessitatibus.</div>
      <div className='separator'/>

      <SocialLinks/>
      
    </footer>
  )
}
